<template>
  <div class="openAccountPageBox">
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      title="开通结算账户"
      width="33%"
      :before-close="handleClose"
    >
      <el-form
        ref="formRef"
        label-position="left"
        label-width="100px"
        :model="formData"
        :rules="rules"
      >
        <el-form-item label="结算银行" prop="settleBankId">
          <el-select
            v-model="formData.settleBankId"
            @change="getSettleBankName"
          >
            <el-option
              v-for="item in $store.getters.getDictionaryItem('settleBankName')"
              :key="item.dictId"
              :label="item.dictName"
              :value="item.dictId"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="银行开户名"
          prop="openBankName"
          :rules="rules.message"
        >
          <el-input v-model="formData.openBankName" placeholder="请输入银行开户名" @input="$forceUpdate()" />
        </el-form-item>
        <el-form-item
          v-if="formData.settleBankId === 'ABC'"
          label="开户银行"
          prop="crBankNo"
          :rules="rules.selectiveType"
        >
          <el-select
            v-model="formData.crBankNo"
            size="large"
            @change="getCrBankNo"
          >
            <el-option
              v-for="item in depositBankList"
              :key="item.dictId"
              :label="item.dictName"
              :value="item.dictId"
            />
          </el-select>
        </el-form-item>
        <div>
          <el-form-item
            v-if="formData.settleBankId === 'ABC'"
            label="银行卡号"
            prop="crAccNo"
            :rules="[
              ...rules.message,
              {
                min: 10,
                max: 20,
                message: '长度在10-20个字符',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formData.crAccNo"
              placeholder="请输入公司银行卡号"
            />
          </el-form-item>
          <el-form-item
            v-else
            label="银行卡号"
            prop="openAccNo"
            :rules="[
              ...rules.message,
              {
                min: 10,
                max: 20,
                message: '长度在10-20个字符',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="formData.openAccNo"
              placeholder="请输入公司银行卡号"
            />
          </el-form-item>
        </div>
        <el-form-item
          v-if="formData.settleBankId !== 'ABC'"
          label="开户行网点"
          prop="openBankNet"
          :rules="rules.message"
        >
          <el-input
            v-model="formData.openBankNet"
            placeholder="请输入开户行网点"
          />
        </el-form-item>
        <el-form-item label="手机号" prop="telNo" :rules="rules.phone">
          <el-input
            v-model="formData.telNo"
            placeholder="请输入银行留存手机号"
          />
        </el-form-item>
        <el-form-item
          v-if="formData.settleBankId === 'ABC'"
          label="短信验证码"
          prop="billNo"
          :rules="[
            ...rules.message,
            { min: 6, max: 6, message: '长度为6个字符', trigger: 'blur' },
          ]"
        >
          <div class="flexBox">
            <el-input v-model="formData.billNo" placeholder="请输入6位数字" />
            <el-button
              class="verification-code"
              :disabled="disabledMyCode"
              @click="getMyCode"
            >
              {{ codeText }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="widen-button" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            class="widen-button"
            @click="openBankAccount"
          >立即开通</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { rules } from '@/unit/matchFormRegex'
import { sendPhoneCode } from '@/http/agentApi/login'
import {
  abcBailPaySubContractSign,
  cmpBankinfoAdd
} from '@/http/agentApi/enterpriseInformation'
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      depositBankList: [],
      formData: {
        openBankName: ''
      },
      rules,
      codeText: '获取验证码',
      disabledMyCode: false
    }
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          const userInfo = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)) || {}
          this.formData.openBankName = userInfo.orgName
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.depositBankList = this.$store.getters.getDictionaryItem('ABC')
  },
  methods: {
    // 获取开户银行name id
    getCrBankNo(data) {
      const currentVal = this.depositBankList.find(
        (item) => item.dictId === data
      )
      this.formData.crBankName = currentVal.dictName
    },
    // 获取结算银行名称
    getSettleBankName(value) {
      // 获取结算银行的名称
      const arr = this.$store.getters.getDictionaryItem('settleBankName')
      arr.forEach((item) => {
        if (item.dictId === value) {
          this.formData.settleBankName = item.dictName
        }
      })
    },
    async openBankAccount() {
      const valid = await this.$refs.formRef.validate().catch(() => false)
      if (!valid) return
      let api = ''
      if (this.formData.settleBankId === 'ABC') {
        this.formData.cmpId = JSON.parse(localStorage.getItem(`${sessionStorage.getItem('systemPlatformRoles') === '03' ? 'userInfoCust' : 'userInfoAgent'}`)).cmpId
        api = abcBailPaySubContractSign
      } else {
        api = cmpBankinfoAdd
      }
      api(this.formData, (res) => {
        this.$message.success('成功！')
        this.handleClose()
      })
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
      this.$emit('getData')
      this.formData = {}
    },
    // 获取验证码
    getMyCode() {
      if (
        !this.formData.telNo ||
        this.formData.telNo === '' ||
        this.formData.telNo.length !== 11
      ) { return this.$message.error('请填写正确的手机号码') }
      sendPhoneCode({ telNo: this.formData.telNo, accType: '1' }, (res) => {
        let maxNum = 60
        const oldCodeText = this.codeText
        this.codeText = `已发送${maxNum}s`
        const codeCountDown = setInterval(() => {
          this.disabledMyCode = true
          const countDownNum = maxNum--
          this.codeText = `已发送${countDownNum}s`
          if (countDownNum === 0) {
            // 倒计时结束
            this.codeText = oldCodeText
            clearInterval(codeCountDown)
            this.disabledMyCode = false
          }
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss">
.openAccountPageBox {
  .el-form-item__content {
    >.el-select,
    >.el-input {
      width: 96%;
    }
    .flexBox{
      display: flex;
      justify-items: center;
      justify-content: space-between;
      .el-button{
        height: 38px;
      }
    }
  }
  .el-dialog__footer {
    text-align: center !important;
  }
  .verification-code {
    margin-left: 10px;
  }
}
</style>
